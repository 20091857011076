import { DatePicker, Form } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

interface PickDateProps {
  onChange?: (date: Dayjs | null, dateString: string | string[]) => void;
  label?: string;
  className?: string;
  name?: string;
  showTime?: boolean;
  style?: React.CSSProperties;
  textColor?: string;
  textSize?: string;
  selectedDate?: string;
  setSelectedDate?: (value: string) => void;
}

const PickDate: React.FC<PickDateProps> = ({
  onChange,
  label = "Select Date",
  className,
  name,
  showTime = false,
  style,
  textColor = "#030229CC",
  textSize = "14px",
  selectedDate,
  setSelectedDate,
}) => {
  const onChangeDateTime = (
    date: Dayjs | null,
    dateString: string | string[]
  ) => {
    if (setSelectedDate && typeof dateString === "string") {
      setSelectedDate(dateString);
    }
    if (onChange) {
      onChange(date, dateString);
    }
  };

  return (
    <Form.Item
      name={name}
      className={className}
      label={<p style={{ fontSize: textSize, color: textColor }}>{label}</p>}
    >
      <DatePicker
        onChange={onChangeDateTime}
        value={selectedDate ? dayjs(selectedDate) : undefined}
        showTime={showTime}
        style={style}
      />
    </Form.Item>
  );
};

export default PickDate;
