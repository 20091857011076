import { useSelector } from "react-redux";
import { Table, TableProps, Tag } from "antd";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { searchValue } from "../../../../utils/setColor";

interface DataType {
  key: string;
  date: any;
  remarks: any;
  amount: number;
  referenceId: string;
  transactionId: string;
  paymentAtt: any;
  status: any;
}

const SubTransactionsTable = (props: any) => {
  const { invoice, company } = useSelector((state: any) => state);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Transaction Type",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: `Amount Paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text) => <p className="font-medium">{text?.toLocaleString()}</p>,
    },
    {
      title: "Transaction Id",
      dataIndex: "referenceId",
      key: "referenceId",
      render: (text, record) => {
        return (
          <p className="font-medium">
            {record?.paymentAtt?.length !== 0
              ? record?.transactionId
              : text}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <Tag
            color={searchValue(text)}
            className="capitalize min-w-[50px] text-center"
          >
            {text}
          </Tag>
        );
      },
    },
  ];

  const dataToDispaly: DataType[] = invoice?.transactions?.data?.map(
    (el: any) => {
      return {
        key: el?._id,
        remarks: el?.remarks,
        date: el?.createdAt?.slice(0, 10),
        amount: el?.amount,
        referenceId: el?.referenceId,
        transactionId: el?.transactionId,
        status: el?.status,
        paymentAtt: el?.paymentAttempts,
        data: el,
      };
    }
  );
  return (
    <div className="w-full h-full text-[#030229] bg-white mt-10">
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={invoice?.transactions?.total}
        count={Math.ceil(invoice?.transactions?.total / props?.limit)}
      >
        <Table<DataType>
          columns={columns}
          dataSource={dataToDispaly}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};
export default SubTransactionsTable;
