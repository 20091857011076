import { notification } from "antd";
import { myAccounts } from ".";
import { mySubscritionActions } from "../subscription/order";

import {
  getAllAccount,
  deleteAccount,
  getOneAccountService,
  createAccountService,
  updateNotesInformation,
  updateOneAccount,
  getAllNotes,
  getAccountStatisticsService,
  createNoteService,
  createCaseService,
  getAllCase,
} from "./services";
import { getAllSubOrder } from "../subscription/order/services";
export const createOrderAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await createAccountService(data, token);
      if (res?.status === 200) {
        dispatch(myAccounts.setNew(res));
        dispatch(myAccounts.setIsFetching(false));
        return true;
      }
      const resAll = await getAllSubOrder(
        token,
        "?status=created&status=pending&status=draft"
      );
      dispatch(mySubscritionActions.setAll(resAll));
      dispatch(myAccounts.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const createNoteAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await createNoteService(data, token);
      if (res?.status === 201) {
        dispatch(myAccounts.setNewNote(res));
        dispatch(myAccounts.setIsFetching(false));
        notification.success({ message: "Note Created Succesfully" });
        return true;
      }
      dispatch(myAccounts.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const createCaseAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await createCaseService(data, token);
      if (res?.status === 201) {
        dispatch(myAccounts.setNewCase(res));
        dispatch(myAccounts.setIsFetching(false));
        notification.success({ message: "Case Created Succesfully" });
        return true;
      }
      dispatch(myAccounts.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllAccountAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await getAllAccount(token, query);
      if (res?.status === 200) {
        dispatch(myAccounts.setAll(res));
        dispatch(myAccounts.setIsFetching(false));
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllNotesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await getAllNotes(token, query);
      if (res?.status === 200) {
        dispatch(myAccounts.setAllNote(res));
        dispatch(myAccounts.setIsFetching(false));
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllCasesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await getAllCase(token, query);
      if (res?.status === 200) {
        dispatch(myAccounts.setCases(res));
        dispatch(myAccounts.setIsFetching(false));
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneAccountAction = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await getOneAccountService(itemId, token);
      if (res?.status === 200) {
        dispatch(myAccounts.setSelected(res?.data));
        dispatch(myAccounts.setIsFetching(false));
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const submitUpdateNotesAction = (
  accountId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await updateNotesInformation(accountId, data, token);
      if (res?.status === 200) {
        dispatch(myAccounts.setUpdateNotes(res?.data));
        dispatch(myAccounts.setIsFetching(false));
        dispatch(myAccounts.setIsFetching(false));
        // notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const deleteOneAccountAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await deleteAccount(token, itemId, data);
      if (res?.status === 200) {
        const resAll = await getAllAccount(token, "?");
        dispatch(myAccounts.setDeleteAccount(res?.data));
        dispatch(myAccounts.setAll(resAll));
        dispatch(myAccounts.setIsFetching(false));
        notification.success({ message: "Account deleted succesfully" });
        return true;
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateOneAccountAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await updateOneAccount(token, itemId, data);
      if (res?.status === 200) {
        // const resAll = await getAllAccount(token, "?");
        dispatch(myAccounts.setUpdateAccount(res?.data));
        // dispatch(myAccounts.setAll(resAll));
        dispatch(myAccounts.setIsFetching(false));
        // notification.success({ message: "Account updated succesfully" });
        return true;
      }
      dispatch(myAccounts.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAccountStatisticsAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setStatIsFetching(true));
      const res = await getAccountStatisticsService(query, token);
      if (res?.status === 200) {
        dispatch(myAccounts.setAccountStatistics(res?.data));
        dispatch(myAccounts.setStatIsFetching(false));
      }
      dispatch(myAccounts.setStatIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
