import React, { useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllEbmItemsAction } from "../../../store/product/actions";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
  checked: boolean;
}

const EBMCountryCodeTable = (props: any) => {
  const { auth, product } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth?.token && props?.productId)
      getAllEbmItemsAction(
        auth?.token,
        `?product=${props?.productId}`
      )(dispatch);
  }, [auth?.token, dispatch, props?.productId]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Model",
      dataIndex: "model",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Item Code",
      dataIndex: "itemCode",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Country",
      dataIndex: "country",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
  ];

  const data = product?.ebmitem?.map((el: any) => {
    return {
      itemCode: el?.itemCd,
      country: el?.country,
      model: el?.itemName,
      data: el,
    };
  });



  return (
    <div className="mt-4 h-[50vh] overflow-y-auto">
      <Table
        columns={columns}
        dataSource={data}
        loading={product?.isFetching}
        pagination={false}
      />
    </div>
  );
};

export default EBMCountryCodeTable;
