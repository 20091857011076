import { DatePicker, Form, Switch } from "antd";
import React, { useState } from "react";
import CustomButton from "../buttons/CustomButton";
import dayjs from "dayjs";
import {
  createNoteAction,
  getAllNotesAction,
} from "../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../inputs/InputText";
import RichTextEditor from "../inputs/RichTextEditor";

const AddNoteForm = (props: any) => {
  const { auth, account } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formattedReminderDate = new Date().toISOString();
  const [selecetedReminderDate, setSelectedReminderDate] = useState(
    formattedReminderDate
  );
  const [textValue, setTextValue] = useState("");
  const [allowNotification, setAllowNotification] = useState(false);

  const onFinish = async (values: any) => {
    const res = await createNoteAction(auth?.token, {
      ...values,
      details: textValue,
      reminderTime: selecetedReminderDate,
      account: props?.sourceId,
      isReminder: allowNotification,
    })(dispatch);
    if (res) {
      await getAllNotesAction(
        auth.token,
        `?account=${props?.sourceId}`
      )(dispatch);
      form.resetFields();
      props.onCancel();
    }
  };
  const onChange = (checked: boolean) => {
    setAllowNotification(checked);
  };
  const onChangeDateTime = (date: any, dateString: any) => {
    setSelectedReminderDate(dateString);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: "100%" }}
      layout="horizontal"
    >
      <div className="flex flex-col">
        <InputText
          name="title"
          label={"Add title"}
          width={"[97.2%]"}
          height={10}
          required={true}
          requiredMessage="Title is required"
        />
        <RichTextEditor
          textStyle={{ fontSize: "16px", color: "#030229CC" }}
          label="Add Notes"
          value={textValue}
          setValue={setTextValue}
          style={{ height: "300px" }}
        />
        <Form.Item
          name="reminderTime"
          className="mt-5"
          label={<p className="text-sm text-[#030229CC]">Set Reminder</p>}
        >
          <DatePicker
            onChange={onChangeDateTime}
            defaultValue={dayjs()}
            showTime
            style={{ width: "30%", height: 35 }}
          />
        </Form.Item>
        <Form.Item name="isReminder">
          <div className=" flex  gap-10 w-full">
            <p className="text-sm text-[#030229CC]">Enable Reminder </p>
            <div className="border border-[#030229] border-opacity-10 px-4 py-2 flex rounded-md text-sm font-medium">
              <p className="pr-2"> {allowNotification ? "On" : "off"}</p>
              <Switch onChange={onChange} defaultChecked={allowNotification} />
            </div>
          </div>
        </Form.Item>
      </div>
      <div className=" flex gap-5 w-full flex-wrap justify-end">
        <Form.Item>
          <CustomButton
            btnName={<h1 className="px-5 py-0.5">Discard</h1>}
            borderColor={"[#030229]"}
          />
        </Form.Item>
        <Form.Item>
          <CustomButton
            btnName={<h1 className="px-6 py-0.5">Save Note</h1>}
            loading={account?.isFetching}
            btnBgColor={"[#605BFF]"}
            loadingColor={"white"}
            textColor={"white"}
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default AddNoteForm;
