import { Form, Select } from "antd";
import React from "react";

interface inputSelectProps {
  name: string;
  label?: string;
  width?: any;
  required?: boolean;
  requiredMessage?: string;
  textColor?: string;
  textSize?: string;
  placeholder?: string;
  onChange?: () => void;
  maxLength?: number;
  selectOption: any;
  style?: any;
  className?: any;
  mode?: "multiple" | "tags" | undefined;
}
const InputSelect = ({
  name,
  label,
  required = false,
  requiredMessage,
  textColor = "[#030229CC]",
  textSize = "sm",
  placeholder,
  selectOption,
  mode,
  style,
  className,
  onChange,
}: inputSelectProps) => {
  return (
    <Form.Item
      className={className}
      name={name}
      label={<p className={`text-${textSize} text-${textColor}`}>{label}</p>}
      rules={[
        {
          required: required,
          message: requiredMessage,
          whitespace: true,
        },
      ]}
    >
      <Select
        showSearch
        style={style}
        placeholder={placeholder}
        optionFilterProp="label"
        onChange={onChange}
        options={selectOption}
        mode={mode}
      />
    </Form.Item>
  );
};

export default InputSelect;
