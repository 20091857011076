import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import CreateCaseForm from "../../../components/forms/CreateCaseForm";
import { ReactComponent as ProductIcon } from "../../../assets/Iconproduct.svg";
import OverViewCard from "../../../components/cards/OverviewCard";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import CustomButton from "../../../components/buttons/CustomButton";
import AppTable from "../../../components/tables/AppTable";
import { FiPlus } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { searchValue } from "../../../utils/setColor";
import CustomizedPieChart from "../subscription/overview/CustomizedPieChart";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCasesAction } from "../../../store/account/actions";
const SupportOverview = () => {
  const { auth, account } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const getThisWeekStartDate = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    const startOfWeek = new Date(
      currentDate.setDate(currentDate.getDate() - diffToMonday)
    );
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  };

  const getThisWeekEndDate = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const diffToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
    const endOfWeek = new Date(
      currentDate.setDate(currentDate.getDate() + diffToSunday)
    );
    endOfWeek.setHours(23, 59, 59, 999);
    return endOfWeek;
  };
  const formattedDate = (date: any) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate.toISOString().slice(0, 10);
  };

  const initialStartDate = formattedDate(getThisWeekStartDate());
  const initialEndDate = formattedDate(getThisWeekEndDate());
  const [addCase, setAddCase] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: getThisWeekStartDate(),
    endDate: getThisWeekEndDate(),
    key: "selection",
  });

  useEffect(() => {
    auth?.token && getAllCasesAction(auth?.token, "")(dispatch);
  }, [dispatch, auth?.token]);

  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    setSelectedDateRange(selection);
    setStartDate(formattedDate(selection.startDate));
    setEndDate(formattedDate(selection.endDate));
  };
  const data = [
    { name: "IN progress", value: 40 },
    { name: "Due", value: 300 },
    { name: "Solved", value: 300 },
    { name: "On Hold", value: 200 },
    { name: "Cancelled", value: 100 },
  ];
  const data2 = [
    { name: "Billing & paym..", value: 35 },
    { name: "Technical Supp..", value: 300 },
    { name: "Account Manag..", value: 300 },
    { name: "Generl Inquiry", value: 200 },
    { name: "Cancellations", value: 100 },
  ];
  const colors = ["#3930A8", "#A0D100", "#12B76A", "#F19D00", "#EC5C2E"];
  const columns = [
    {
      title: "Updated",
      dataIndex: "updated",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Case Id",
      dataIndex: "caseId",
    },
    {
      title: "Case Category",
      dataIndex: "caseCategory",
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
    },
    {
      title: "Reporter",
      dataIndex: "reporter",
    },
    {
      title: "status",
      dataIndex: "status",
      render: (text: any) => {
        return (
          <div
            style={{
              backgroundColor: searchValue(text),
            }}
            className="rounded-2xl w-[7rem] py-2 text-center text-white"
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "data",
      render: () => {
        return (
          <div>
            <BsThreeDotsVertical size={20} />
          </div>
        );
      },
    },
  ];

  const casesData = account.cases?.data?.map((el: any) => {
    return {
      key: el?._id,
      assignee: "John Brown",
      updated: el?.updatedAt?.slice(0, 10),
      createdAt: el?.createdAt?.slice(0, 10),
      caseCategory: el?.categories,
      department: el?.department,
      status: "solved",
      reporter: "Bernardine",
      caseId: "csf00123",
    };
  });

  return (
    <div className="text-black pt-3">
      <DetailsHeaderActionBar
        pageName="Support"
        title=" "
        goBack={() => setAddCase(false)}
      />
      {addCase ? (
        <CreateCaseForm closeCase={() => setAddCase(false)} />
      ) : (
        <>
          <div className=" 2xl:flex gap-5 w-full">
            <div className="w-full 2xl:w-2/3">
              <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                <OverViewCard
                  total={10}
                  icon={<ProductIcon />}
                  text="All Cases"
                />
                <OverViewCard
                  total={10}
                  icon={<ProductIcon />}
                  text="Cases In progress"
                />
                <OverViewCard
                  total={10}
                  icon={<ProductIcon />}
                  text="Cases Solved"
                />
              </div>
              <div className="xl:flex gap-6 mt-5">
                <div className="md:flex gap-6 w-full">
                  <div className="bg-white p-4 h-[30j0px] w-full md:w-1/2 rounded-md relative">
                    <h2 className="text-base font-poppins font-medium mb-4 text-black pl-5">
                      Case status
                    </h2>
                    <CustomizedPieChart
                      data={data}
                      colors={colors}
                      totalLabel={<h1 className="text-[12px]">Total</h1>}
                      innerRad={40}
                      height={"180px"}
                      contentWidth={"w-full"}
                      top={"50%"}
                      left={"37%"}
                      outerRad={60}
                      totalValue={50}
                      pieHeight={200}
                      pieWidth={300}
                    />
                  </div>
                  <div className="bg-white p-4 w-full h-s[300px] mt-5 md:mt-0 rounded-md relative">
                    <h2 className="text-base font-poppins font-medium mb-4 text-black pl-5">
                      CASE PRIORITY
                    </h2>
                    <div className="flex gap-5 my-3 mt-10">
                      <span className="text-sm font-medium text-[#030229B2] w-[30%]">
                        High
                      </span>
                      <div className="w-[70%] bg-[#EFEFFF] rounded-full h-4 mt-1">
                        <div
                          className=" bg-[#605BFF]  h-4 rounded-full"
                          style={{ width: "70%" }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-[#030229B2] w-[15%] pt-1">
                        70%
                      </span>
                    </div>
                    <div className="flex gap-5 my-3 mt-10">
                      <span className="text-sm font-medium text-[#030229B2] w-[30%]">
                        Medium
                      </span>
                      <div className="w-[70%] bg-[#EFEFFF] rounded-full h-4 mt-1">
                        <div
                          className=" bg-[#EC5C2E]  h-4 rounded-full"
                          style={{ width: "20%" }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-[#030229B2] w-[15%] pt-1">
                        20%
                      </span>
                    </div>
                    <div className="flex gap-5 my-3 mt-10">
                      <span className="text-sm font-medium text-[#030229B2] w-[30%]">
                        Low
                      </span>
                      <div className="w-[70%] bg-[#EFEFFF] rounded-full h-4 mt-1">
                        <div
                          className=" bg-[#DE2C2F]  h-4 rounded-full"
                          style={{ width: "10%" }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-[#030229B2] w-[15%] pt-1">
                        10%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 h-[300spx] w-full xl:w-1/3 mt-5 xl:mt-0 rounded-md relative">
                  <h2 className="text-base font-poppins font-medium mb-4 text-black pl-5">
                    Case Category
                  </h2>
                  <CustomizedPieChart
                    data={data2}
                    colors={colors}
                    totalLabel={<h1 className="text-[12px]">Total</h1>}
                    innerRad={40}
                    height={"180px"}
                    contentWidth={"w-full"}
                    top={"50%"}
                    left={"37%"}
                    outerRad={60}
                    totalValue={50}
                    pieHeight={200}
                    pieWidth={300}
                  />
                </div>
              </div>
            </div>
            <div className="w-full 2xl:w-2/6 mt-5 2xl:mt-0">
              <DateRangePicker
                className="xw-[80%] xmd:w-[10%] pb-4 text-black"
                onChange={handleSelect}
                showPreview
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={[selectedDateRange]}
                direction="vertical"
                locale={enUS}
              />
            </div>
          </div>
          <div className="bg-white mt-5">
            <div className="flex justify-between m-5 pt-10">
              <h1>Recent cases</h1>
              <div className="flex gap-5">
                <CustomButton
                  btnName={"New Case"}
                  btnBgColor={"[#605BFF]"}
                  textColor={"white"}
                  icon={<FiPlus color="white" size={20} />}
                  onClick={() => setAddCase(true)}
                />
              </div>
            </div>
            <div className="w-full overflow-x-auto">
              <AppTable
                columns={columns}
                dataSource={casesData}
                loading={account?.isFetching}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SupportOverview;
