import { Routes, Route, useLocation } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import LoginByScan from "../pages/LoginByScan";
import DashboardRoutes from "./DashRoutes";
import LoginProtection from "./LoginProtection";

// Talal web  stats
import Statistics from "../pages/dashboard/Statistics";
import Dashboard from "../pages/dashboard/dashboardComponents/Dashboard";
import Product from "../pages/dashboard/Product";
import Business2Business from "../pages/dashboard/Busines2Business";
import Channel from "../pages/dashboard/Channel";
import HummanResource from "../pages/dashboard/HummanResource";
import ProformaReply from "../pages/ProformaReply";
import PoReply from "../pages/PoReply";
import DeliveryNote from "../pages/DeliveryNote";
import SuccessPage from "../components/Results/SuccessfullySent";
//Invetories
import InvetoryRoutes from "./InventoryRoutes";
import WareHouseIn from "../pages/dashboard/inventory/WareHouseIn";
import WareHouseInValidate from "../pages/dashboard/inventory/ValidatePO";
import WareHouseInStock from "../pages/dashboard/inventory/Stockin";
import Inventory from "../pages/dashboard/inventory/Inventory";
import InventorySearch from "../pages/dashboard/inventory/InventorySearch";
import InventoryProduct from "../pages/dashboard/inventory/Product";
import ProductHistory from "../pages/dashboard/inventory/ProductHistory";
import WareHouseOut from "../pages/dashboard/inventory/warehouseOut/WareHouseOut";
import ReturnedStock from "../pages/dashboard/inventory/returnedStock/ReturnedStock";
//Purchases
import PurchaseRoutes from "./PurchasesRoutes";
import Purchase from "../pages/dashboard/Purchase";
import PurchasesProduct from "../pages/dashboard/purchases/Supplier";
import NewQuotationView from "../pages/dashboard/purchases/quotation/NewQuotation";
import ViewQuotationView from "../pages/dashboard/purchases/quotation/viewQuotation";

//Customers
import CustomerRoutes from "./CustomerRoutes";
import Customer from "../pages/dashboard/Customers";
import CustomerOverview from "../pages/dashboard/customer/OverviewPage";
import CustomerDetails from "../pages/dashboard/customer/CustomerDetails";

//Repairs
import RepairRoutes from "./RepairsRoutes";

//human resource
import HRRoutes from "./HRRoutes";

//Channels
import ChannelRoutes from "./ChannelRoutes";
import ChannelList from "../pages/dashboard/channels/Channel";
import PriceList from "../pages/dashboard/channels/PriceList";

import PointOfSales from "../pages/dashboard/PointOfSales";
import QuotationView from "../pages/dashboard/purchases/Quotation";
import ProformaView from "../pages/dashboard/purchases/Proforma";
import PurchaseOrderView from "../pages/dashboard/purchases/PurchaseOrder";
import Supplier from "../pages/dashboard/Supplier";
import PageNotFound from "../pages/PageNotFound";
import SupplierDetails from "../pages/dashboard/purchases/supplierDetails/SupplierDetails";
import ProductDetails from "../pages/dashboard/inventory/ProductDetails";
import PachaseOrderDetais from "../pages/dashboard/purchases/PurchaseOrderDetails";
import PurchaseOrderConfirm from "../pages/dashboard/purchases/PurchaseOrderConfirm";
import SinglePurchaseOrderDetails from "../pages/dashboard/purchases/SinglePurchaseOrderDetails";
import Shops from "../pages/dashboard/channels/Shops";
import ShopDetails from "../pages/dashboard/channels/ShopDetails";
import Agents from "../pages/dashboard/channels/Agents";
import FinanceRoutes from "./FinanceRoutes";
import Finance from "../pages/dashboard/finance/Finance";
import FinanceDeliveryNote from "../pages/dashboard/finance/FinanceDeliveryNote";
import DeliveryNoteDetails from "../pages/dashboard/finance/DeliveryNoteDetails";
import AddNewProduct from "../pages/dashboard/channels/priceList/NewProduct";
import POSRoutes from "./POSRoutes";
import SaleWithPOS from "../pages/dashboard/POS/SaleWithPOS";
import POSTransactions from "../pages/dashboard/POS/POSTransactions";
import TransactionsDetails from "../components/cards/transactionDetails/ViewTransaction";
import POSStock from "../pages/dashboard/POS/POSStock";
import NewRequestView from "../pages/dashboard/channels/NewRequestView";
import WarehouseStock from "../pages/dashboard/inventory/warehouseStock";
import ProductDetailsStock from "../pages/dashboard/inventory/warehouseStock/ProductDetailsStock";
import ProductModelUnitsList from "../pages/dashboard/inventory/warehouseStock/ProductModelUnitsList";
import CreateWarehouseOut from "../pages/dashboard/inventory/warehouseOut/WsCreate";
import POSSale from "../pages/dashboard/POS/POSSales";
import FinanceNosale from "../pages/dashboard/finance/nosale/Nosale";

//pos
import PosPriceList from "../pages/dashboard/POS/PriceList";
import Nosale from "../pages/dashboard/POS/nosale/Nosale";
import PosStockRequest from "../pages/dashboard/POS/requestStock/POSRequestStock";

//Settings
import SettingRoutes from "./SettingRoutes";
import SettingOverView from "../pages/dashboard/setting/SettingOverview";
import Employees from "../pages/dashboard/setting/Employees";
import EmployeeDetails from "../pages/dashboard/setting/EmployeeDetails";
import IndexPage from "../pages/dashboard/setting/terms_conditions";
import ViewTermsConditions from "../pages/dashboard/setting/terms_conditions/viewTerms";

//reset password
import ResetPassword from "../pages/ResetPassword";
import ViewSingleRecent from "../pages/dashboard/POS/viewRecent/ViewSingleRecent";
import Transfer from "../pages/dashboard/channels/transfer/Transfer";
import NewRequestTransfer from "../pages/dashboard/channels/transfer/NewRequestTransfer";
import ProductStockItems from "../pages/dashboard/inventory/warehouseStock/ProductStockItems";

// EBM
import EBMItems from "../pages/dashboard/ebm/EBMItems";

// Repairs
import RepairsOverview from "../apps/repairs/pages/OverviewPage";
import Repairs from "../apps/repairs/pages/AllRepairs";
import SalesRepRoutes from "./SalesRepRoutes";
import SalesTable from "../components/tables/salesTable/SalesTable";

// Save the children
import SaveTheChildrenRoutes from "./saveTheChildrenRoutes";
import OverView from "../pages/dashboard/saveTheChildren/OverView";

// Knowledge
// import { Knowledge as _ } from "../apps/knowledge";
import Knowledge from "../apps/knowledge/Knowledge";
import KnowledgeManagers from "../apps/knowledge/KnowledgeManagers";
import KnowledgeAgents from "../apps/knowledge/KnowledgeAgents";
import ChannelPosAndSub from "../pages/dashboard/channels/Stream";

//subscription

import SubscriptionRoutes from "./SubscriptionRoutes";
import SubscriptionPage from "../pages/dashboard/subscription/Accounts/SubscriptionPage";
import SubscriptionDetails from "../pages/dashboard/subscription/orders/SubscriptionOrdersDetails";
import OrdersCard from "../pages/dashboard/subscription/orders/OrdersCard";
import PicklistCard from "../pages/dashboard/subscription/picklist/PicklistCard";
import SubscriptionPicklistDetails from "../pages/dashboard/subscription/picklist/SubscriptionPicklistDetails";
import SubscriptionDeliveryNoteCard from "../pages/dashboard/subscription/DeliveryNote/SubscriptionDeliveryNote";
import SubscriptionDeliveryNoteDetails from "../pages/dashboard/subscription/DeliveryNote/SubscriptionDeliveryNoteDetails";
import AccountInformation from "../pages/dashboard/subscription/Accounts/accountInformation";
import Invoice from "../pages/dashboard/subscription/Accounts/Invoice";
import PageView from "../pages/dashboard/subscription/overview";
// POS subscriptions
import SubscriptionOverView from "../pages/dashboard/channels/subscription/OverView";
import SubscriptionPriceList from "../pages/dashboard/channels/subscription/SubscriptionPriceList";
import CustomerInvoiceDetails from "../pages/dashboard/customer/CustomerInvoiceDetails";
import SubscriptionRequest from "../pages/dashboard/subscription/SubscriptionRequest";
import Vault from "../pages/dashboard/setting/Vault";
import InvoiceOverview from "../pages/dashboard/subscription/billings/overview/InvoiceOverview";
import SubscriptionBillings from "../pages/dashboard/subscription/billings/subscriptionbillings/subscriptionBillings";
import Purchased from "../pages/dashboard/purchases/Purchased";
import VaultRoutes from "./vaultRoutes";
import IndexVault from "../pages/dashboard/vaults";
import ResetPasswordForm from "../pages/ResetPasswordForm";
import InventoryReport from "../pages/dashboard/report/InventoryReport";
import PriceListDetails from "../components/grids/priceList/priceListDetails";
import Commission from "../pages/dashboard/finance/commission/commission";
import AllB2CIssues from "../apps/repairs/pages/AllB2CIssues";
import CommissionList from "../pages/dashboard/channels/CommissionList";
import AgentOverView from "../pages/dashboard/channels/agentProfile/AgentOverView";
import EbmRoutes from "./EbmRoutes";
import EBMOverview from "../apps/EBM/pages/EBMOverview";
import HROverview from "../apps/humanresource/pages/overview/OverviewPage";
import Department from "../apps/humanresource/pages/department/Department";
import Attendance from "../apps/humanresource/pages/attendance/Attendance";
import Payroll from "../apps/humanresource/pages/payroll/Payroll";
import Careers from "../apps/humanresource/pages/careers/Careers";
import DeliveryRoutes from "./DeliveryRoutes";
import { determineSearchFrom } from "../utils/converter";
import { useEffect } from "react";
import { myLayoutActions } from "../store/layout";
import Discount from "../pages/dashboard/finance/discount";

import DeliveryDetails from "../pages/dashboard/delivery/delivery/deliveryDetails";
import { useDispatch } from "react-redux";
import SalesRoutes from "./SalesRoutes";
import SalesPages from "../pages/dashboard/sales/SalesPages";
import CreateNewDeal from "../pages/dashboard/subscription/orders/CreateNewDeal";
import DeliveryNoteTrack from "../pages/dashboard/delivery/deliverynote/deliveryNoteTrack";
import Track from "../pages/dashboard/delivery/track/track";
import DeliverypageView from "../pages/dashboard/delivery";
import Delivery from "../pages/dashboard/delivery/delivery/delivery";
import CompleteDeliverySteps from "../pages/dashboard/CompleteDeliverySteps/Delivery";
import BusinessProductDetails from "../pages/dashboard/setting/company/BusinessProductDetails";
import StockRequestOverview from "../pages/dashboard/inventory/warehouseOut/stockRequest";
import WarehouseOutCreate from "../pages/dashboard/inventory/warehouseOut/WarehouseOutCreate";
import ReturnedStockCofirm from "../pages/dashboard/inventory/returnedStock/ReturnedStockConfirm";
import SupportRoutes from "./SupportRoutes";
import SupportOverview from "../pages/dashboard/support/supportOverview";

const MainRouter = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname } = location;
    const searchFrom = determineSearchFrom(pathname);
    dispatch(myLayoutActions.setSearchFrom(searchFrom));
  }, [location, dispatch]);

  return (
    <Routes>
      <Route path="/reply/proforma/:id" element={<ProformaReply />} />
      <Route path="/reset/password/" element={<ResetPassword />} />
      <Route
        path="user/reset_password/:resetToken"
        element={<ResetPasswordForm />}
      />
      <Route path="/reply/po/:id" element={<PoReply />} />
      <Route path="/delivery/note/:id" element={<DeliveryNote />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/statistics" element={<Statistics />} />
      <Route path="/stats" element={<Statistics />} />
      <Route path="/delivery" element={<CompleteDeliverySteps />} />
      <Route element={<LoginProtection />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/scan" element={<LoginByScan />} />
      </Route>
      <Route element={<DashboardRoutes />}>
        {/* <Route path="/home" element={<LandingPage dashback={true} />} /> */}

        {/* ++++++++++++++++ Purchases +++++++++++++++++++++++++++++ */}
        <Route element={<PurchaseRoutes />}>
          <Route path="/knowledge" element={<Knowledge />} />
          <Route path="/knowledge/manager" element={<KnowledgeManagers />} />
          <Route path="/knowledge/agents" element={<KnowledgeAgents />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route
            path="/purchase/rfq"
            element={<QuotationView route={"purchase"} />}
          />
          <Route path="/purchase/rfq/:id" element={<NewQuotationView />} />
          <Route
            path="/purchase/rfq/:id/:rfqId"
            element={<ViewQuotationView />}
          />
          <Route path="/purchase/suppliers" element={<PurchasesProduct />} />
          <Route path="/purchase/supplier/:id" element={<SupplierDetails />} />
          <Route
            path="/purchase/quotation/:id"
            element={<NewQuotationView />}
          />
          <Route path="/purchase/proforma" element={<ProformaView />} />
          <Route path="/purchase/po" element={<PurchaseOrderView />} />
          <Route path="/purchase/po/:po_id" element={<PachaseOrderDetais />} />
          <Route
            path="/purchase/po/confirm/:po_id"
            element={<PurchaseOrderConfirm />}
          />
          <Route
            path="/purchase/po/details/:po_id"
            element={<SinglePurchaseOrderDetails isSales={true} />}
          />
        </Route>

        {/* ++++++++++++++++ Inventory +++++++++++++++++++++++++++++ */}
        <Route element={<InvetoryRoutes />}>
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/inventory/pr" element={<InventoryProduct />} />
          <Route path="/inventory/pr/product" element={<ProductHistory />} />
          <Route path="/inventory/pr-search" element={<InventorySearch />} />
          <Route path="/inventory/ws/in" element={<WareHouseIn />} />
          <Route path="/inventory/returnedstock" element={<ReturnedStock />} />
          <Route
            path="/inventory/returnedstock/:id"
            element={<ReturnedStockCofirm />}
          />
          <Route
            path="/inventory/rfq"
            element={<QuotationView route={"inventory"} />}
          />
          <Route path="/inventory/rfq/:id" element={<NewQuotationView />} />
          <Route
            path="/inventory/rfq/:id/:rfqId"
            element={<ViewQuotationView />}
          />
          <Route
            path="/inventory/ws/in/:deliveryNoteId"
            element={
              <DeliveryNoteDetails
                pathName={"Delivery Note | "}
                pageName="Warehouse In"
              />
            }
          />
          <Route path="/inventory/ws/out" element={<WareHouseOut />} />
          <Route
            path="/inventory/ws/out/:id"
            element={<CreateWarehouseOut />}
          />
          <Route
            path="/inventory/ws/out/req/:rfsId"
            element={<WarehouseOutCreate />}
          />
          <Route
            path="/inventory/ws/in/validate/:id"
            element={<WareHouseInValidate />}
          />
          <Route path="/inventory/ws/in/stock" element={<WareHouseInStock />} />
          <Route path="/inventory/pr/:productId" element={<ProductDetails />} />
          <Route path="/inventory/ws/stock" element={<WarehouseStock />} />
          <Route
            path="/inventory/ws/stock/:productType"
            element={<ProductDetailsStock />}
          />
          <Route
            path="/inventory/ws/stock/:productType/details"
            element={<ProductModelUnitsList />}
          />
          <Route
            path="/inventory/ws/stock/:productType/items"
            element={<ProductStockItems />}
          />
          <Route
            path="/inventory/PickList"
            element={<PicklistCard routeName={"inventory"} />}
          />
        </Route>
        <Route element={<ChannelRoutes isNotTab={true} />}>
          <Route
            path="/channel"
            element={<ChannelList channelRoute="channel" />}
          />
          {/* <Route path="/channel/:channelName" element={<ChannelPosAndSub />} /> */}
        </Route>
        <Route element={<ChannelRoutes />}>
          <Route
            path="/channel/:channelName"
            element={<SubscriptionOverView />}
          />
          <Route
            path="/channel/:channelName/pos/overview"
            element={<SubscriptionOverView />}
            // element={<ChannelDashboard />} // TODO: WE HAVE TO WORK ON OVERVIEW OF CHANNEL SPECIFICALLY
          />
          <Route path="/channel/:channelName/pos/shops" element={<Shops />} />
          <Route
            path="/channel/:channelName/pos/request-stock/:rfsId"
            element={<NewRequestView />}
          />
          <Route
            path="/channel/:channelName/pos/shops/:shopId"
            element={<ShopDetails />}
          />
          <Route
            path="/channel/:channelName/pos/shops/:shopId/:rfsId"
            element={<NewRequestView />}
          />
          <Route path="/channel/:channelName/pos/agents" element={<Agents />} />
          {/* <Route
            path="/channel/:channelName/pos/agents/:id"
            element={<AgentsDetails />}
          /> */}
          <Route
            path="/channel/:channelName/pos/agents/:id"
            element={<AgentOverView />}
          />
          <Route
            path="/channel/:channelName/pos/prices"
            element={<PriceList />}
          />
          <Route
            path="/channel/:channelName/pos/commissions"
            element={<CommissionList />}
          />
          <Route
            path="/channel/:channelName/pos/prices/:priceId"
            element={<PriceListDetails />}
          />
          <Route
            path="/channel/:channelName/prices/add"
            element={<AddNewProduct />}
          />
          <Route
            path="/channel/:channelName/pos/request-stock"
            element={<StockRequestOverview route="channel" />}
          />
          <Route
            path="/channel/:channelName/pos/transfer"
            element={<Transfer />}
          />
          <Route
            path="/channel/:channelName/pos/transfer/request"
            element={<NewRequestTransfer />}
          />

          <Route
            path="/channel/:channelName/sub/overview"
            element={<SubscriptionOverView />}
          />
          <Route
            path="/channel/:channelName/sub/prices"
            element={<SubscriptionPriceList />}
          />
        </Route>

        <Route element={<SubscriptionRoutes />}>
          <Route path="/subscription" element={<PageView />} />
          <Route path="/subscription/account" element={<SubscriptionPage />} />
          <Route
            path="/subscription/account/:accountId"
            element={<AccountInformation />}
          />
          <Route path="/subscription/order" element={<OrdersCard />} />
          <Route
            path="/subscription/order/new/:dealId"
            element={<CreateNewDeal />}
          />
          <Route
            path="/subscription/order/:orderId"
            element={<SubscriptionDetails route={"subscrition"} />}
          />
          <Route
            path="/Subscription/Accounts/MiNADEF-RWANDA/:id"
            element={<Invoice />}
          />
          <Route
            path="/subscription/createOrder"
            element={<ChannelPosAndSub />}
          />
          <Route
            path="/subscription/PickList"
            element={<PicklistCard routeName={"subscription"} />}
          />
          <Route
            path="/:routeName/PickList/:orderId"
            element={<SubscriptionPicklistDetails />}
          />
          <Route
            path="/subscription/DeliveryNote"
            element={<SubscriptionDeliveryNoteCard />}
          />

          <Route
            path="/subscription/DeliveryNote/:deliveryNoteId"
            element={<SubscriptionDeliveryNoteDetails />}
          />
          <Route
            path="subscription/request"
            element={<SubscriptionRequest />}
          />
          <Route
            path="subscription/devices"
            element={<ChannelList channelRoute="subscription" />}
          />
          <Route
            path="subscription/devices/:channel/:subChannelId"
            element={<SubscriptionPriceList />}
          />
          <Route path="/subscription/invoice" element={<InvoiceOverview />} />
          <Route
            path="subscription/invoice/billings"
            element={<SubscriptionBillings />}
          />
        </Route>
        <Route element={<DeliveryRoutes />}>
          <Route path="/overview/delivery" element={<DeliverypageView />} />
          <Route path="/deliveryOrder" element={<Delivery />} />
          <Route
            path="/deliveryOrder/:deliveryNoteId"
            element={<DeliveryDetails />}
          />
          <Route path="/track" element={<Track />} />
          <Route path="/track/:id" element={<Track />} />
          <Route path="/deliveryNoteTrack" element={<DeliveryNoteTrack />} />
        </Route>
        {/* Finance route */}
        <Route element={<FinanceRoutes />}>
          <Route path="/finance" element={<Finance />} />
          <Route
            path="/finance/delivery-note"
            element={<FinanceDeliveryNote />}
          />
          <Route
            path="/finance/delivery-note/:deliveryNoteId"
            element={<DeliveryNoteDetails />}
          />
          <Route path="/finance/po" element={<PurchaseOrderView />} />
          <Route path="/finance/no-sales" element={<FinanceNosale />} />
          <Route
            path="/finance/po/details/:po_id"
            element={<SinglePurchaseOrderDetails isFinance={true} />}
          />
          <Route
            path="/finance/transactions"
            element={<POSTransactions route={"finance"} />}
          />
          <Route path="/finance/assets" element={<Purchased />} />
          <Route path="/finance/commission" element={<Commission />} />
          <Route path="/finance/ebm/items" element={<EBMItems />} />
          <Route path="/finance/ebm/purchases" element={<EBMItems />} />
          <Route path="/finance/discount" element={<Discount />} />
        </Route>

        <Route element={<SalesRoutes />}>
          <Route path="/sales/quotation" element={<SalesPages />} />
          <Route
            path="/sales/quotion/:orderId"
            element={<SubscriptionDetails route={"sales"} />}
          />
        </Route>

        {/* ++++++++++++++++ Customers +++++++++++++++++++++++++++++ */}
        <Route element={<CustomerRoutes />}>
          <Route path="/customers/overview" element={<CustomerOverview />} />
          <Route path="/customers" element={<Customer />} />
          <Route path="/customers/:customerId" element={<CustomerDetails />} />
          <Route
            path="/customers/:customerId/invoice"
            element={<CustomerInvoiceDetails />}
          />
        </Route>

        {/* salesReport */}
        <Route element={<SalesRepRoutes />}>
          <Route
            path="/report/sales"
            element={<SalesTable route={"finance"} />}
          />
          <Route path="/report/inventory" element={<InventoryReport />} />
          {/* <Route
            path="/sales/nosales"
            element={<NosaleTable />}
          /> */}
          <Route path="/sales/nosales" element={<FinanceNosale />} />
        </Route>
        <Route element={<RepairRoutes />}>
          <Route path="/repairs/overview" element={<RepairsOverview />} />
          <Route path="/repairs" element={<Repairs />} />
          <Route path="/repairs/issues" element={<AllB2CIssues />} />
          <Route path="/repairs/:repairId" element={<CustomerDetails />} />
        </Route>
        <Route element={<SupportRoutes />}>
          <Route path="/support/overview" element={<SupportOverview />} />
          <Route path="/support/case" element={<Repairs />} />
        </Route>
        <Route element={<HRRoutes />}>
          <Route path="/overview" element={<HROverview />} />
          <Route path="/department" element={<Department />} />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/payroll" element={<Payroll />} />
          <Route path="/careers" element={<Careers />} />
        </Route>

        {/* POS */}
        <Route element={<POSRoutes />}>
          <Route path="/pos" element={<PointOfSales />} />
          <Route path="/pos/sale" element={<POSSale />} />
          <Route path="/pos/sale/:cartId" element={<SaleWithPOS />} />
          <Route
            path="/pos/transactions"
            element={<POSTransactions route={"pos"} />}
          />
          <Route
            path="/pos/transactions/:cartId"
            element={<TransactionsDetails />}
          />
          <Route path="/pos/stock" element={<POSStock />} />
          <Route path="/pos/prices" element={<PosPriceList />} />
          <Route path="/pos/nosale" element={<Nosale />} />
          <Route path="/pos/request" element={<PosStockRequest />} />
          <Route path="/pos/request/:rfsId" element={<NewRequestView />} />
          <Route path="/pos/:rfsId" element={<ViewSingleRecent />} />
        </Route>

        {/* Settings */}
        <Route element={<SettingRoutes />}>
          <Route path="/setting" element={<SettingOverView />} />
          <Route
            path="/setting/bunsiness-product/:businessProductId"
            element={<BusinessProductDetails />}
          />
          <Route
            path="/setting/bunsiness-product/:businessProductId/:formId"
            element={<BusinessProductDetails />}
          />
          <Route path="/setting/employee" element={<Employees />} />
          <Route path="/setting/employee/:id" element={<EmployeeDetails />} />
          <Route path="/setting/vault" element={<Vault />} />
          <Route path="/setting/terms&conditions" element={<IndexPage />} />
          <Route
            path="/setting/terms&conditions/:termsId"
            element={<ViewTermsConditions />}
          />
        </Route>

        {/* Ebm Routes */}
        <Route element={<EbmRoutes />}>
          <Route path="/ebm" element={<EBMOverview />} />
        </Route>

        {/* save the children */}
        <Route element={<SaveTheChildrenRoutes />}>
          <Route path="/report/transaction" element={<OverView />} />
        </Route>
        {/* vaults */}
        <Route element={<VaultRoutes />}>
          <Route path="/vaults" element={<IndexVault />} />
        </Route>

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/splash" element={<Dashboard />} />
        <Route path="/b2b" element={<Business2Business />} />
        <Route path="/channel" element={<Channel />} />
        <Route path="/hr" element={<HummanResource />} />
        <Route path="/supplier" element={<Supplier />} />
        <Route path="/product" element={<Product index={1} />} />
        <Route path="/*" element={<PageNotFound />} />
      </Route>
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};
export default MainRouter;
