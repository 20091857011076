import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../components/DetailsPage";
import ProductForm from "../../../components/forms/NewProductForm";
import DeleteModal from "../../../components/Modals/DeleteModal";
import StockProductTable from "./Tables/StockProductTable";
import {
  deleteProductsAction,
  getAllEbmItemsAction,
  getOneProductAction,
  getOneProductStoreAction,
} from "../../../store/product/actions";
import ProductDetailsTable from "../../../components/tables/ProductDetailsTable";
import { Stack } from "@mui/material";
import ProductStoreFilter from "../../../components/filters/ProductStoreFiltering";
import SupplierByBrandTable from "../../../components/tables/SupplierByBrandTable";
import EBMCountryCodeTable from "../../../components/tables/EBM/EBMCountryCodeTable";
import { getSinglePricelistItemsAction } from "../../../store/channel/actions";

type TAB = {
  title: string;
  component: any;
};

const ProductDetails = (props: any) => {
  const { productId } = useParams();
  const { auth, product, channel } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedhannel, setSelectedChannel] = useState("");
  const [selectedStatus, setselectedStatus] = useState("");
   const [limit, setLimit] = useState<any>(15);
    const [page, setPage] = useState<any>(1);

  useEffect(() => {
    if (auth?.token && productId) {
      getSinglePricelistItemsAction(auth?.token, productId)(dispatch);
    }
  }, [auth?.token, productId, dispatch, selectedStatus]);

  useEffect(() => {
    if (auth?.token && productId)
      getAllEbmItemsAction(auth?.token, `?product=${productId}`)(dispatch);
  }, [auth?.token, dispatch, productId]);

  React.useEffect(() => {
    if (productId) {
      auth?.token &&
        getOneProductStoreAction(
          auth?.token,
          productId,
          `?status=${selectedStatus}&page=${page - 1}&limit=${limit}`
        )(dispatch);
    }
  }, [productId, auth, dispatch, selectedStatus,page,limit]);

  const tabs: TAB[] = [
    {
      title: `Stock`,
      component: (
        <Stack direction={"column"} style={{ position: "relative" }}>
          <Stack direction={"row"} spacing={2}>
            <ProductStoreFilter
              selectedhannel={selectedhannel}
              setSelectedChannel={setSelectedChannel}
              selectedStatus={selectedStatus}
              setselectedStatus={setselectedStatus}
            />
          </Stack>
          <StockProductTable />
        </Stack>
      ),
    },
    {
      title: "Prices",
      component: (
        <Stack direction={"column"} style={{ position: "relative" }}>
          <ProductDetailsTable />
        </Stack>
      ),
    },
    {
      title: "Suppliers",
      component: (
        <Stack direction={"column"} style={{ position: "relative" }}>
          <SupplierByBrandTable />
        </Stack>
      ),
    },
    {
      title: "RRA Details",
      component: (
        <Stack direction={"column"} style={{ position: "relative" }}>
          <EBMCountryCodeTable
            productId={
              channel?.selectedPriceListItems?.data?.product?._id || productId
            }
          />
        </Stack>
      ),
    },
  ];

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await deleteProductsAction(auth?.token, {
        selectedIds: [product?.selected?._id],
      })(dispatch));
    navigate("/inventory/pr");
  };

  const handleClickEdit = () => {
    console.log("editing a product....");
  };

  const handleCancels = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (productId) {
        console.log("fetching....");
        auth?.token &&
          (await getOneProductAction(auth?.token, productId)(dispatch));
      }
    };
    fetchOnProduct();
  }, [productId, auth, dispatch]);
  return (
    <div>
      <DetailsPage
        UpdateForm={ProductForm}
        pageItemId={productId}
        specsData={product.selected && product.selected?.specs}
        tabs={tabs}
        pageName={"Device"}
        title={product?.selected?.model}
        simpleDetails={product && product.selected}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={product.isFetching}
        onCancel={handleCancels}
        itemName="Device"
      />
    </div>
  );
};

export default ProductDetails;
