import React, { useState } from "react";
import { Button } from "antd";
import CustomModal from "./CustomModal";
import AddNoteForm from "../forms/addNoteForm";
interface addNoteProps {
  sourceId: string;
}
const AddNotes = ({ sourceId }: addNoteProps) => {
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const showNoteModal = () => {
    setOpenNoteModal(true);
  };
  const handleClose = () => {
    setOpenNoteModal(false);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={showNoteModal}
        className="pt-1 bg-[#605BFF]"
      >
        <p className="text-sm font-medium">Add New Note</p>
      </Button>

      <CustomModal
        openModal={openNoteModal}
        cancelModal={handleClose}
        closeModal={handleClose}
        width={800}
        title={
          <h1 className="text-base font-medium text-[#030229CC] py-3">
            Add note
          </h1>
        }
        component={
          <div>
            <AddNoteForm onCancel={handleClose} sourceId={sourceId} />
          </div>
        }
      />
    </div>
  );
};

export default AddNotes;
