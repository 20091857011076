import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAccountIncoiceService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/invoice${query}`, token);
};

export const getAllSubsTransactionService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/subTransaction${query}`, token);
};

export const getOneInvoiceService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/invoice/one/${id}`, token);
};

export const approvePaymentInvoice = async (
  itemId: string,
  token: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/invoice/pay/${itemId}`,
    data,
    token
  );
};

export const payWithMomo = async (token: string, data: any) => {
  return HttpRequest.post(`${SERVER_URL}/pay/invoice/momo`, data, token);
};

export const checkMomoPaymentStatusService = async (
  token: string,
  query: any
) => {
  return HttpRequest.get(
    `${SERVER_URL}/pay/invoice/repaymentWithMomo${query}`,
    token
  );
};

export const checkoutCart = async (
  token: string,
  cartId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/cart/checkout/${cartId}`,
    data,
    token
  );
};

export const AdjustAccountBilling = async (token: string, data: any) => {
  return HttpRequest.post(
    `${SERVER_URL}/account/fixAccountBilling`,
    data,
    token
  );
};
