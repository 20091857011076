import React from "react";
import { Table, Tag } from "antd";
import type { TableColumnsType } from "antd";

import { useSelector } from "react-redux";
import { searchValue } from "../../../utils/setColor";

interface DataType {
  key: React.Key;
  names: string;
  paymentDate: string;
  bill: string;
  paid: string;
  method: string;
  dueDate: string;
  status: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Customers",
    dataIndex: "names",
    key: "names",
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
    key: "paymentDate",
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    key: "amountPaid",
  },
  {
    title: "Amount To Be Paid",
    dataIndex: "amountToBePaid",
    key: "amountToBePaid",
  },
  {
    title: "Payment Method",
    dataIndex: "method",
    key: "method",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    key: "dueDate",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const PageView = () => {
  const { overView } = useSelector((state: any) => state);

  const dataSource = overView?.recentPayment?.data.map((recentPayment: any) => {
    return {
      key: recentPayment?._id,
      names: recentPayment?.account?.customerDetails?.name,
      paymentDate: recentPayment?.payment?.date.slice(0, 10),
      method: recentPayment?.payment?.mode,
      amountPaid: recentPayment?.amountPaid,
      amountToBePaid: recentPayment?.amountToBePaid,
      dueDate: recentPayment?.dueDate?.slice(0, 10),
      status: (
        <Tag
          color={searchValue(recentPayment?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {recentPayment?.status}
        </Tag>
      ),
    };
  });

  return (
    <Table<DataType>
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      loading={overView?.isFetching}
    />
  );
};

export default PageView;
