import React, { useState, useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { ReactComponent as OverviewIcon } from "../../../../assets/icons/dashboard/Icon.svg";
import { DatePickerProps, Select } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import OverviewChart from "./overviewChart";
import OrdersHistory from "./ordersHistory";
import {
  getAllNoSaleAction,
  getAllPaymentHistoryAction,
  getDevicesFinancedUnderBusinessProductsAction,
  getDevicesFinancedUnderChannelsAction,
  getLiveStockAtShopAction,
  getLiveStockAtWarehouseAction,
  getRevenueQuantityAction,
  getTotalRevenueAction,
  getRecentPaymentAction,
  getStatsPlanServiceAction,
} from "../../../../store/subscription/overview/actions";

import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import CustomButton from "../../../../components/buttons/CustomButton";
import { FaCaretDown } from "react-icons/fa";
import FinancingLevel from "./financingLevel";
import NoSaleSubscriptionTable from "../../../../components/tables/subscription/NoSaleSubscriptionTable";
import PaymentHistorySubscriptionTable from "../../../../components/tables/subscription/PaymenHistorySubscriptionTable";

import CustomizedPieChart from "./CustomizedPieChart";
import DatePickerCustom from "../../../../components/buttons/DatePicker";

import { getAllChannelAction } from "../../../../store/channel/actions";
import { handleSelectedChannelAction } from "../../../../store/layout/actions";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { HiOutlineUsers } from "react-icons/hi2";
import { TotalAmountPaid, TotalDevices } from "./OverviewCardData";
import ChannelFilter from "./FilterChannels";
import { PlansOverview, SoldDevices } from "./DeviceSold";
import { ShopStock, WarehouseStock } from "./InventoryOverview";
import { getAllRegionsAction } from "../../../../store/shop/actions";
import { getShopStatsActions } from "../../../../store/dashboard/actions";

import OverViewSubCard from "./OverViewSubCard";
import { calculatePercentageChange } from "../../../../utils/converter";
import { getDevicesStatusAction } from "../../../../store/vault/actions";

interface secProp {
  name?: any;
  value?: any;
  bgColor?: any;
}

const calculatePercentage = (part: number, total: number): string => {
  if (typeof part !== "number" || typeof total !== "number") {
    return "0.00";
  }
  if (total === 0) {
    return "0.00";
  }

  const percentage = (part / total) * 100;
  return percentage.toFixed(2);
};

export const DeviceSecurity = ({ name, value, bgColor }: secProp) => {
  return (
    <>
      <div className="flex gap-3">
        <div
          className={`bg-${bgColor} w-[16px] h-[12px] rounded-sm mt-1`}
        ></div>
        <div>
          <p className="text-[#605BFF] text-[14px] font-medium">{name}</p>
          <p className="text-[#030229] text-[12px] pl-1">{value}</p>
        </div>
      </div>
    </>
  );
};

const PageView = () => {
  const { auth, overView, company, shop, channel, vault } = useSelector(
    (state: any) => state
  );
  const [periodValue, setPeriodValue] = useState("range");
  const formattedDate = new Date().toISOString().slice(0, 10);
  const dispatch = useDispatch();
  const channels = auth?.user?.channel;
  const initialChannel = channels && channels?.at(0)?._id;
  const [selectedChannel, setSelectedChannel] = useState("");
  let todayDate = moment();
  let previousDate = todayDate.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = useState(formattedPreviousDate);
  const [enteredComparedDate, setComparedDate] = useState(formattedDate);
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const mondayOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
  const currentYear = moment().format("YYYY");
  const [selectedRegion, setSelectedRegion] = useState<string>("");

  const periodOptions = [
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Daily</p>
      ),
      value: "daily",
    },
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Weekly</p>
      ),
      value: "weekly",
    },
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Monthly</p>
      ),
      value: "month",
    },
    {
      label: (
        <p className="py-1 px-2  rounded-md text-center text-base">Yearly</p>
      ),
      value: "year",
    },
    {
      label: (
        <p className="py-2 px-2  rounded-md text-center text-base">Range</p>
      ),
      value: "range",
    },
  ];

  const COLORS = [
    "#5F00FFB2",
    "#E25668B2",
    "#E256AEB2",
    "#E28956B2",
    "#CF56E2B2",
    "#8A56E2B2",
  ];
  const onChange = (value: string) => {
    setPeriodValue(value);
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };
  const onChangeComparedDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setComparedDate(dateString);
  };
  // Channel
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);
  useEffect(() => {
    initialChannel && setSelectedChannel(initialChannel);
    (initialChannel || selectedChannel) &&
      handleSelectedChannelAction(initialChannel ?? selectedChannel)(dispatch);
  }, [dispatch, initialChannel, selectedChannel]);

  useEffect(() => {
    if (auth.token) {
      getAllPaymentHistoryAction(auth.token, "?")(dispatch);
    }
  }, [dispatch, auth.token]);

  // Total Revenue
  useEffect(() => {
    if (auth.token) {
      if (periodValue === "daily") {
        getTotalRevenueAction(
          auth?.token,
          `?day=daily&date1=${yesterday}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "weekly") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${mondayOfWeek}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "month") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${firstDayOfMonth}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "year") {
        getTotalRevenueAction(
          auth?.token,
          `?year=${currentYear}&channel=${initialChannel ?? selectedChannel}`
        )(dispatch);
      }
      if (periodValue === "range") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth.token,
    periodValue,
    yesterday,
    today,
    currentYear,
    mondayOfWeek,
    firstDayOfMonth,
    enteredDate,
    enteredComparedDate,
    initialChannel,
    selectedChannel,
  ]);

  useEffect(() => {
    if (auth?.token && enteredDate && enteredComparedDate) {
      getShopStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${enteredComparedDate}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, enteredDate, enteredComparedDate]);

  // No Sales
  useEffect(() => {
    if (auth?.token) {
      if (periodValue === "daily") {
        getAllNoSaleAction(
          auth?.token,
          `?day=daily&date2=${yesterday}&date1=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "weekly") {
        getAllNoSaleAction(
          auth?.token,
          `?date2=${mondayOfWeek}&date1=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "month") {
        getAllNoSaleAction(
          auth?.token,
          `?date2=${firstDayOfMonth}&date1=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "year") {
        getAllNoSaleAction(
          auth?.token,
          `?year=${currentYear}&channel=${initialChannel ?? selectedChannel}`
        )(dispatch);
      }
      if (periodValue === "range") {
        getAllNoSaleAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
    }
  }, [
    enteredDate,
    dispatch,
    auth?.token,
    periodValue,
    yesterday,
    currentYear,
    firstDayOfMonth,
    today,
    mondayOfWeek,
    enteredComparedDate,
    initialChannel,
    selectedChannel,
  ]);
  useEffect(() => {
    if (auth.token) {
      const channelQuery = selectedChannel ? `?channel=${selectedChannel}` : "";
      getAllRegionsAction(auth.token, channelQuery)(dispatch);
    }
  }, [dispatch, auth.token, selectedChannel]);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth.token) {
      const regionQuery = selectedRegion ? `?region=${selectedRegion}` : "";
      getLiveStockAtShopAction(auth.token, regionQuery)(dispatch);
      getLiveStockAtWarehouseAction(auth.token)(dispatch);
    }
  }, [dispatch, auth.token, selectedRegion]);

  useEffect(() => {
    if (auth.token) {
      getDevicesFinancedUnderChannelsAction(auth.token)(dispatch);
      getDevicesFinancedUnderBusinessProductsAction(auth.token)(dispatch);
    }
  }, [dispatch, auth.token]);

  useEffect(() => {
    if (auth.token) {
      getRecentPaymentAction(
        auth.token,
        `?channel=${initialChannel ?? selectedChannel}`
      )(dispatch);
    }
  }, [dispatch, auth.token, initialChannel, selectedChannel]);

  // Quantity financed & Amount
  useEffect(() => {
    if (auth.token) {
      if (periodValue === "daily") {
        getRevenueQuantityAction(
          auth.token,
          `?date1=${yesterday}&date2=${today}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
      if (periodValue === "range") {
        getRevenueQuantityAction(
          auth.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}&channel=${
            initialChannel ?? selectedChannel
          }`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth.token,
    periodValue,
    enteredDate,
    enteredComparedDate,
    initialChannel,
    selectedChannel,
    yesterday,
    today,
  ]);

  useEffect(() => {
    if (auth?.token) {
      getDevicesStatusAction(auth?.token, ``)(dispatch);
      getStatsPlanServiceAction(auth?.token)(dispatch);
    }
  }, [auth.token, dispatch]);

  function transformStatusData(vault: any): { name: string; value: number }[] {
    const data: any = vault?.statusCount?.data;

    return Object.values(data || {}).map((item: any) => {
      if (typeof item === "object" && item !== null) {
        const [key, value] = Object.entries(item)[0];
        return {
          name: key || "Unknown",
          value: value || 0,
        };
      } else {
        return {
          name: item || "Unknown",
          value: data?.[item] || 0,
        };
      }
    });
  }

  const transformedData = transformStatusData(vault);
  const totalDeviceInTheVault = transformedData?.reduce(
    (curr: number, item: any) => curr + (item?.value || 0),
    0
  );

  const percentaLate = calculatePercentageChange(
    overView?.quantityRevenue?.data?.latePaymentsDay2,
    overView?.quantityRevenue?.data?.latePaymentsDay1
  );

  const totalStatistic = overView?.devicesFinancedUnderChannels?.data?.reduce(
    (acc: number, item: any) => acc + (item?.total || 0),
    0
  );

  return (
    <div className="w-full min-h-screen mt-6 mb-10 text-[#030229]">
      <div className="text-[#0F0F47] mb-4 flex flex-col xl:flex-row justify-between">
        <DetailsHeaderActionBar pageName="Subscription" title={"Overview"} />
        <div className="space-x-6 flex">
          {periodValue === "range" ? (
            <div className="flex gap-3 border border-[#0302291A] p-1 rounded-md">
              <DatePickerCustom
                onChangeDate={onChangeDate}
                enteredDate={enteredDate}
              />
              <DatePickerCustom
                onChangeDate={onChangeComparedDate}
                enteredDate={enteredComparedDate}
              />
              <FaCaretDown
                onClick={() => setPeriodValue("")}
                color="#03022980"
                className="mt-1 cursor-pointer"
                size={20}
              />
            </div>
          ) : (
            <Select
              placeholder="Select Period"
              style={{ width: 200 }}
              defaultValue="range"
              optionFilterProp="label"
              onChange={onChange}
              options={periodOptions}
              size="large"
            />
          )}
        </div>
      </div>
      <div className={`flex space-x-2 items-center justify-between`}>
        {["partner"]?.includes(auth?.user?.role) ? (
          <h1 className="font-medium"> RWANDA's SALES KPI DASHBOARD</h1>
        ) : (
          <div className="sm:flex gap-5">
            <ChannelFilter setSelectedChannel={setSelectedChannel} />
          </div>
        )}

        <div></div>
      </div>
      <div className={`w-full grid xl:grid-cols-2 gap-8 mt-5`}>
        <div>
          <OverviewChart
            data={overView?.totalRevenue?.data}
            periodValue={periodValue}
          />
        </div>
        <div>
          <OrdersHistory
            data={overView?.noSale?.data}
            periodValue={periodValue}
          />
          <NoSaleSubscriptionTable
            data={overView?.noSale?.data?.date1noSales}
          />
        </div>
      </div>

      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
        <TotalDevices />
        <TotalAmountPaid />
        <OverViewSubCard
          title={"Total OutStanding Balance"}
          amount={`${getDefaultCurrencyCode(company)} ${
            overView?.quantityRevenue?.data?.outstandingAmount
              ?.allOutstandingBalance || 0
          }`}
          icon={<OverviewIcon />}
          textColor={"[#F1595C]"}
          description={"Across all customers"}
          btn={
            <CustomButton
              btnName={<p className="text-[10px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />

        <OverViewSubCard
          title={"Late  Payments"}
          amount={`${getDefaultCurrencyCode(company)} ${
            overView?.quantityRevenue?.data?.latePaymentsDay2 || 0
          }`}
          icon={<HiOutlineUsers color="605BFF" size={24} />}
          comparedAmount={`${getDefaultCurrencyCode(company)} ${
            overView?.quantityRevenue?.data?.latePaymentsDay1 || 0
          }`}
          textColor={"[#F1595C]"}
          parcent={
            <p
              className={`${
                percentaLate?.type === "increase"
                  ? "text-[#57C38A]"
                  : percentaLate?.type === "decrease"
                  ? "text-[#F1595C]"
                  : "text-[#605BFF]"
              }`}
            >
              {" "}
              {`${percentaLate?.percentage}%`}
            </p>
          }
          icon2={
            percentaLate?.type === "increase" ? (
              <FaArrowUpLong color="#57C38A" />
            ) : percentaLate?.type === "decrease" ? (
              <FaArrowDownLong color="#F1595C" />
            ) : (
              ""
            )
          }
          compareText={`${
            percentaLate?.type === "increase"
              ? "Increased"
              : percentaLate?.type === "decrease"
              ? "Decreased"
              : "Increased"
          }`}
          btn={
            <CustomButton
              btnName={<p className="text-[10px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
      </div>
      <div className="w-full mt-[2.5rem] xbg-green-300">
        <div className="grid md:grid-cols-2 gap-4">
          <div className="bg-white rounded-md p-5 text-black">
            <h1 className="text-[#030229] text-base pb-2">
              Devices Financed Under Channels
            </h1>
            {overView?.devicesFinancedUnderChannels?.data?.map((item: any) => (
              <FinancingLevel
                name={`${item?.channelName || "unknown"}`}
                width={calculatePercentage(item?.total, totalStatistic)}
                amount={`${item?.total}`}
              />
            ))}
          </div>
          <div className="bg-white rounded-md p-5 text-black">
            <h1 className="text-[#030229] text-base pb-2">
              Devices Financed Under Business Products
            </h1>
            {overView?.devicesFinancedUnderBusinessProducts?.data?.map(
              (item: any) => (
                <FinancingLevel
                  name={`${item?.channelName || "unknown"}`}
                  width={calculatePercentage(item?.total, totalStatistic)}
                  amount={`${item?.total}`}
                />
              )
            )}
          </div>
        </div>
      </div>

      <div className="hidden xl:block text-[20px] font-medium ml-4 opacity-90 my-8">
        Inventory Overview
      </div>
      <div className="hidden xl:flex gap-5 w-full mt-5">
        <div className="bg-white rounded-md p-5 w-full xl:w-2/4 mt-5 xl:mt-0">
          <h1 className="text-[#030229] text-lg">Vault Status</h1>
          <CustomizedPieChart
            data={transformedData}
            colors={COLORS}
            totalLabel={<h1 className="text-[12px]">Total Devices</h1>}
            innerRad={48}
            height={"180px"}
            contentWidth={"w-full"}
            top={"50%"}
            left={"37%"}
            outerRad={60}
            totalValue={totalDeviceInTheVault}
            pieHeight={200}
            pieWidth={300}
          />
        </div>
        <ShopStock
          overView={overView}
          regions={shop?.allRegions?.data}
          onRegionChange={setSelectedRegion}
          channel={channel}
          onChannelChnage={setSelectedChannel}
        />
      </div>
      <div className=" xl:flex gap-5 w-full mt-5">
        <WarehouseStock overView={overView} />
      </div>
      <div className="hidden xl:block text-[20px] font-medium ml-4 opacity-90 my-8">
        Shop Perfomance and Plan Overview
      </div>
      <div className="hidden xl:flex gap-5 w-full mt-5">
        <div className="bg-white rounded-md p-5 w-full h-[60vh] overflow-auto xl:w-2/4 mt-5 xl:mt-0">
          <PlansOverview />
        </div>
        <SoldDevices />
      </div>

      <div className="flex min-w-full gap-4 mt-6">
        <div className="w-full mt-5 text-black bg-white rounded-md p-5 h-[80vh] overflow-y-auto">
          <div className="flex justify-between py-3">
            <h1 className="text-[#030229] text-xl font-light">
              Recent Payment
            </h1>
          </div>
          <PaymentHistorySubscriptionTable />
        </div>
      </div>
    </div>
  );
};

export default PageView;
