import { Editor } from "primereact/editor";

interface editorProps {
  value: string;
  setValue: (e: string) => void;
  style?: any;
  textStyle?: any;
  label?: string;
}
function RichTextEditor({
  value,
  setValue,
  style,
  label,
  textStyle,
}: editorProps) {
  return (
    <div className="flex gap-5">
      <p style={textStyle}>{label}</p>
      <Editor
        value={value}
        onTextChange={(e: any) => setValue(e.htmlValue)}
        style={style}
      />
    </div>
  );
}

export default RichTextEditor;
