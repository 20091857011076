import React, { useEffect, useState } from "react";
import InputSelect from "../inputs/InputSelect";
import { Form, Select } from "antd";
import CustomButton from "../buttons/CustomButton";
import RichTextEditor from "../inputs/RichTextEditor";
import PickDate from "../inputs/PickDate";
import InputText from "../inputs/InputText";
import {
  createCaseAction,
  createNoteAction,
  getAllNotesAction,
} from "../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../store/setting/actions";

interface caseFormProps {
  sourceId: any;
}
const CreateCaseForm = (props: any) => {
  const { auth, account, setting } = useSelector((state: any) => state);
  const [textValue, setTextValue] = useState("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedDueDate, setSelectedDueDate] = useState<string>("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const usersOption = setting?.all?.data.map((el: any) => ({
    value: el?._id,
    label: el?.names,
  }));

  useEffect(() => {
    auth?.token && getAllUsersAction(auth?.token, "")(dispatch);
  }, [dispatch, auth?.token]);
  const onFinish = async (values: any) => {
    await createCaseAction(auth?.token, {
      ...values,
      description: textValue,
      isReminderOn: true,
      reminder: [{ status: "", scheduledTime: selectedDate }],
      assignedTo: {
        user: values.user,
      },
    })(dispatch);
    await getAllNotesAction(auth.token, `?`)(dispatch);
    form.resetFields();
    props?.closeCase();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: "100%" }}
      layout="horizontal"
    >
      <div className="lg:flex gap-5 text-[#030229]">
        <div className="bg-white rounded-md px-5 py-10 w-full lg:w-[50%] 2xl:w-[68%] ">
          <div className="w-[100%] mx-auto">
            <h1 className="uppercase pb-5 text-lg">New Case</h1>
            <div className="flex flex-col">
              <InputText
                name="title"
                label={"Add title"}
                width={"[100%]"}
                height={11}
                required={true}
                requiredMessage="Title is required"
              />
              <RichTextEditor
                textStyle={{ fontSize: "16px", color: "#030229CC" }}
                label="Add Notes"
                value={textValue}
                setValue={setTextValue}
                style={{ height: "300px", width: "100%" }}
              />
              <div className="mt-5">
                <InputSelect
                  style={{ height: 42 }}
                  name="department"
                  selectOption={[
                    {
                      value: "Finance",
                      label: "Finance",
                    },
                    {
                      value: "Dev",
                      label: "Dev",
                    },
                  ]}
                  label="Department"
                  placeholder="Select department"
                />
              </div>
            </div>
            <div className=" flex gap-5 w-full flex-wrap justify-end">
              <Form.Item>
                <CustomButton
                  btnName={<h1 className="px-5 py-1">Discard</h1>}
                  borderColor={"[#030229]"}
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  btnName={<h1 className="px-6 py-1">Save Case</h1>}
                  loading={account?.isFetching}
                  btnBgColor={"[#605BFF]"}
                  loadingColor={"white"}
                  textColor={"white"}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-md p-10 w-full mt-5 lg:mt-0 lg:w-[45%] 2xl:w-[35%]">
          <div className="mt-5 divide-y-2 w-[80%] mx-auto">
            <Form.Item
              name="user"
              label={<p className={`text-sm text-[#030229CC]`}>Assignees</p>}
            >
              <Select
                mode="multiple"
                placeholder="Outlined"
                style={{ flex: 1 }}
                options={usersOption}
              />
            </Form.Item>
            <InputSelect
              className={"pt-5"}
              style={{ height: 35 }}
              name="customer"
              selectOption={[
                {
                  value: "aliane",
                  label: "Aliane",
                },
                {
                  value: "alle",
                  label: "Alle",
                },
              ]}
              label="Customer"
              placeholder="Search by Name"
            />
            <InputSelect
              className={"pt-5"}
              style={{ height: 35 }}
              name="categories"
              selectOption={[
                {
                  value: "Technical Support",
                  label: "Technical Support",
                },
              ]}
              label="Category"
              placeholder="Search by Category"
            />
            <InputSelect
              className={"pt-5"}
              style={{ height: 35 }}
              name="subcategories"
              selectOption={[
                {
                  value: "Software issue",
                  label: "software issue",
                },
              ]}
              label="Category"
              placeholder="Search by Category"
            />
            <InputSelect
              className={"pt-5"}
              style={{ height: 35 }}
              name="priority"
              selectOption={[
                {
                  value: "aliane",
                  label: "Aliane",
                },
                {
                  value: "alle",
                  label: "Alle",
                },
              ]}
              label="Priority"
              placeholder="Search Priority"
            />
            <PickDate
              label="Set Reminder Date"
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              className="pt-5"
              textSize="16px"
              style={{ width: "100%", height: 40 }}
            />
            <PickDate
              label="Set Due Date"
              selectedDate={selectedDueDate}
              setSelectedDate={setSelectedDueDate}
              className="pt-5"
              textSize="16px"
              style={{ width: "100%", height: 40 }}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default CreateCaseForm;
